<!--
 * @Descripttion: 
 * @version: 
 * @Author: xyh
 * @Date: 2021-04-13 10:04:21
 * @LastEditTime: 2021-04-13 10:18:05
-->
<template>
  <div class="common_title">{{title}}</div>
</template>

<script>
export default {
props:{
    title :{
        type:String,
    
    }
}
}
</script>

<style lang="less" scoped>
.common_title{
    font-size: 16px;
    color: #333;
    padding-left: 9px;
    border-left: 2px solid #347CF5;
    margin-bottom: 20px;
}
</style>