<!--
 * @Description: 财务管理》充值订单
 * @Author: HuangCheng
 * @Date: 2020-02-21 15:25:15
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-04-22 11:36:15
 -->

<template>
  
    <div style="padding:24px">
    <!-- <div class="headers">
      <div>
        <span>充值金额</span>
        <span>￥{{headInfo.totalRechargeAmount}}</span>
      </div>
      <div>
        <span>充值笔数</span>
        <span>{{headInfo.rechargeCount}}</span>
      </div>
    </div> -->
    <commonTitle title="充值订单" />
    
    <div class="table-page-search-wrapper" style="max-width:1450px;margin-bottom:20px">
      <a-form layout="inline" :form="addRechargeForm" :hideRequiredMark="true">
        <a-row :gutter="0" style="margin-bottom:20px">
          <!-- <a-col :lg="6" :md="8" :sm="12">
            <a-form-item label="充值单号">
              <a-input :max-length="maxLenth" v-model="queryParam.id" placeholder="请输入充值单号"/>
            </a-form-item>
          </a-col> -->
          <a-col :lg="8" :md="8" :sm="12">
            <a-form-item label="企业名称">
              <a-input v-model="queryParam.realName" placeholder="请输入企业名称" style="width:260px"/>
            </a-form-item>
          </a-col>
          <a-col :lg="8" :md="8" :sm="12">
            <a-form-item label="审核时间">
              <a-range-picker v-model="date" @change="handleDateChange" style="width:260px"/>
            </a-form-item>
          </a-col>
          <a-col :lg="8" :md="8" :sm="12">
            <a-form-item label="充值状态"  >
            <a-select placeholder="请选择充值状态" style="width: 260px"
               v-model="queryParam.status">
              <a-select-option value="101001">充值中</a-select-option>
              <a-select-option value="101011">充值成功</a-select-option>
              <a-select-option value="101021">充值失败</a-select-option>
            </a-select>
            </a-form-item>
          </a-col>
          
        </a-row>
        <a-row :gutter="24">
            <a-col :lg="12" :md="8" :sm="12">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" icon="search" @click="handleToSearchRecharge">查询</a-button>
              <a-button style="margin-left: 18px" icon="reload" @click="resetSearchForm">重置</a-button>
              <a-button style="margin-left: 18px"  type="primary" icon="plus" @click="addRechargeItem">充值</a-button>
              <!-- <a-button style="margin-left: 18px" type="primary" @click="exportTable('充值订单报表')" v-has="'financialManage:czddexport'">导出Excel</a-button> -->
            </span>
          </a-col>
        </a-row>
      </a-form> 
    </div>
    
    <div >
    <s-table
      ref="table"
      size="default"
      :rowKey="(record) => record.id"
      :columns="columns"
      :data="loadDataList"
      bordered
    >
     
    </s-table>
    </div>
    <!-- 审核 -->
    <a-modal title="审核" width="600px" destroyOnClose :visible="visibleExamineModal" @ok="handleRechargeModalOk" @cancel="handleExamineModalCancel">
      <a-form :form="examinForm">
        <a-row class="form-row">
          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item label="充值金额" :labelCol="{lg: {span: 4}, sm: {span: 5}}" :wrapperCol="{lg: {span: 20}, sm: {span: 19} }">
              <a-input placeholder="请输入充值金额"
                v-decorator="['rechargeAmount', {rules: [{ required: true, message: '请输入充值金额', whitespace: true}]}]" />
            </a-form-item>
          </a-col>
          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item label="充值状态" :labelCol="{lg: {span: 4}, sm: {span: 5}}" :wrapperCol="{lg: {span: 20}, sm: {span: 19} }">
              <a-select placeholder="请选择充值状态" style="width: 100%"
                v-decorator="[
                  'rechargeStatus',{rules: [{ required: true, message: '请选择充值状态', whitespace: true}]}
                ]">
                <a-select-option value="101011">审核成功</a-select-option>
                <a-select-option value="101021">审核失败</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item label="充值方式" :labelCol="{lg: {span: 4}, sm: {span: 5}}" :wrapperCol="{lg: {span: 20}, sm: {span: 19} }">
              <a-select placeholder="请选择充值方式" style="width: 100%"
                v-decorator="[
                  'rechargeMode',{rules: [{ required: true, message: '请选择充值方式', whitespace: true}]}
                ]">
                <a-select-option value="100921">
                  正常充值
                </a-select-option>
                <a-select-option value="100922">
                  借款充值
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item label="备注" :labelCol="{lg: {span: 4}, sm: {span: 5}}" :wrapperCol="{lg: {span: 20}, sm: {span: 19} }">
              <a-textarea
                placeholder="请输入备注信息"
                :auto-size="{ minRows: 3, maxRows: 5 }"
                v-decorator="[ 'remark' ]"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
    <!-- 添加 -->
    <a-modal title="充值" width="600px" destroyOnClose :visible="addRechargeVisible" @ok="handleAddRechargeModalOk" @cancel="handleAddModalCancel">
      <a-form :form="addRechargeForm" :hideRequiredMark="true">
        <a-row class="form-row">
          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item label="企业名称" :labelCol="{lg: {span: 4}, sm: {span: 5}}" :wrapperCol="{lg: {span: 20}, sm: {span: 19} }">
              <a-select showSearch :defaultActiveFirstOption="false" :filterOption="false" @search="handleSearch" :notFoundContent="null" placeholder="请选择企业" style="width: 100%"
                v-decorator="[
                  'enterpriseId',{rules: [{ required: true, message: '请选择企业', whitespace: true}]}
                ]">
                <a-select-option v-for="(item, index) in conpanyLists" :key="index" :value="item.id">{{item.enterpriseName}}</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item label="充值金额" :labelCol="{lg: {span: 4}, sm: {span: 5}}" :wrapperCol="{lg: {span: 20}, sm: {span: 19} }">
              <a-input placeholder="请输入充值金额"
                v-decorator="['rechargeAmount', {rules: [{ required: true, message: '请输入充值金额', whitespace: true}]}]" />
            </a-form-item>
          </a-col>
          
          <!-- <a-col :lg="24" :md="24" :sm="24">
            <a-form-item label="充值方式" :labelCol="{lg: {span: 4}, sm: {span: 5}}" :wrapperCol="{lg: {span: 20}, sm: {span: 19} }">
              <a-select placeholder="请选择充值方式" style="width: 100%"
                v-decorator="[
                  'rechargeMode',{rules: [{ required: true, message: '请选择充值方式', whitespace: true}]}
                ]">
                <a-select-option value="100921">
                  正常充值
                </a-select-option>
                <a-select-option value="100922">
                  借款充值
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col> -->
          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item label="备注" :labelCol="{lg: {span: 4}, sm: {span: 5}}" :wrapperCol="{lg: {span: 20}, sm: {span: 19} }">
              <a-textarea
                placeholder="请输入备注信息"
                :auto-size="{ minRows: 3, maxRows: 5 }"
                v-decorator="[ 'remark' ]"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
  import Vue from 'vue'
  import STable from '@/components/table/'
  import AInput from "ant-design-vue/es/input/Input"
  import debounce from 'lodash/debounce';
  import moment from 'moment';
  import commonTitle from "../components/commonTitle";
  import { getRechargeList, examineRecharge, addEnterpriseWalletRecharge, getEnterpriseDownBox, exportBbExcel, rechargeStatistics } from '@/api/api';
  export default {
    name: "RechargeOrder",
    // mixins: [JeecgListMixin],
    components: {
      AInput,
      STable,
      commonTitle,
    },
    data () {
      const cellStyle = {
        maxWidth: '120px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow:'ellipsis',
        cursor:'pointer',
      };
      return {
        maxLenth: 27,
        queryParam: {
          id: '', // 单号
          realName: this.$route.query.enterpriseName || this.$route.query.name || '', // 企业名称
          auditStartDate: '',
          auditFinishDate: '',
          status: undefined
        },
        date: [],
        // 表头
        columns: [
         
          {
            title: '企业工商注册名称',
            align: 'center',
            dataIndex: 'realName',
            width: '220px'
          },
          
          {
            title: '充值金额(元)',
            align: 'center',
            dataIndex: 'rechargeAmount',
            needTotal: true,
            // customRender: (text) => text.toFixed(2) || 0,
            width: '200px'
          },
          {
            title: '充值时间',
            align: 'center',
            dataIndex: 'rechargeTime',
            width: '200px'
          },
          
          {
            title: '充值状态',
            align: 'center',
            dataIndex: 'status',
            customRender: (text) => text === '101001' ? '充值中' : text === '101011' ? '充值成功' : text === '101021' ? '充值失败' : '',
            width: '200px'
          },
          // {
          //   title: '开票状态',
          //   align: 'center',
          //   dataIndex: 'invoiceStatus',
          //   customRender: (text) => text === '1' ? '已开票' : text === '2' ? '未开票' :'',
          //   width: '150px'
          // },
          
          {
            title: '审核时间',
            align: 'center',
            dataIndex: 'auditTime',
            width: '200px'
          },
          
          {
            title: '备注',
            align: 'center',
            dataIndex: 'remark',
            width: '130px',
            customCell: () => {
              return {
                style: cellStyle
              }
            },
            customRender: (text) => <a-tooltip placement="top" title={text}>{text}</a-tooltip>
          },
          
        ],
        loadDataList: (parameter) => {
          return getRechargeList(Object.assign(parameter, this.queryParam))
            .then(res => {
              
              return res.result
            })
        },
        currentId:'',
        examinForm: this.$form.createForm(this),
        addRechargeForm: this.$form.createForm(this),
        visibleExamineModal: false,
        addRechargeVisible: false,
        conpanyLists: [],
        headInfo: Object
      }
    },
    created () {
      this.handleSearch('')
      if(this.$route.query.countDate!==''&&this.$route.query.countDate!==undefined){
        this.date=[moment(this.$route.query.countDate),moment(this.$route.query.countDate)]
        this.queryParam.auditStartDate = this.$route.query.countDate + ' 00:00:00'
        this.queryParam.auditFinishDate = this.$route.query.countDate + ' 23:59:59'
      }
      // this.rechargeStatistics()
    },
    methods: {
      // 获取充值笔数、总金额
      rechargeStatistics () {
        rechargeStatistics(this.queryParam).then(res => {
          if(res.success) {
            this.headInfo = res.result
            this.headInfo.totalRechargeAmount = this.headInfo.totalRechargeAmount === null ? '0.00' : this.headInfo.totalRechargeAmount.toFixed(2)
            this.headInfo.rechargeCount = this.headInfo.rechargeCount === null ? '0' : this.headInfo.rechargeCount
            // if (!this.$store.state.user.userRules.includes('user:setStar')) {
            //   let account = /.{1,}/
            //   this.headInfo.totalRechargeAmount = String(this.headInfo.totalRechargeAmount).replace(account, "***")
            //   this.headInfo.rechargeCount = String(this.headInfo.rechargeCount).replace(account, "***")
            // } else {
            //   this.headInfo.totalRechargeAmount = Number(this.headInfo.totalRechargeAmount).toFixed(2)
            // }
          }
        })
      },
      // 选择日期
      handleDateChange(date, dateString) {
        this.queryParam.auditStartDate = dateString[0] + ' 00:00:00'
        this.queryParam.auditFinishDate = dateString[1] + ' 23:59:59'
      },
      // 添加充值订单
      addRechargeItem () {
        this.addRechargeVisible = true
        setTimeout(()=>{
            this.addRechargeForm.setFieldsValue({ rechargeMode: '100921' })
        },0)
      },
      // 搜索企业
      handleSearch (value) {
        setTimeout(this.getEnterpriseDownBox({enterpriseName: value}), 1000)
      },
      /**
       * @description: 查询企业充值列表
       * @return: void
       * @Author: HuangCheng
       */      
      handleToSearchRecharge() {
        this.$refs.table.refresh(true)
        // this.rechargeStatistics()
      },
      // 重置
      resetSearchForm () {
        this.queryParam = {
          id: '', // 单号
          realName: '', // 企业名称
          auditStartDate: '',
          auditFinishDate: '',
          status: undefined
        }
        this.date = []
        this.$refs.table.refresh(true)
        // this.rechargeStatistics()
      },
      /**
       * @description: 审核按钮点击事件
       * @param : record 当前行数据
       * @return: void
       * @Author: HuangCheng
       */
      handleExamine(record) {
        setTimeout(()=>{
            this.examinForm.setFieldsValue({ rechargeAmount: record.rechargeAmount + '' });
            this.examinForm.setFieldsValue({ rechargeMode: record.rechargeMode});
            this.examinForm.setFieldsValue({ remark: record.remark });
        },0)
        this.currentId = record.id;
        this.visibleExamineModal = true;
      },
      /**
       * @description: 审核
       * @param : record 当前行数据
       * @param : status 审核状态（101011:审核成功;101021:审核失败)）
       * @return: void
       * @Author: HuangCheng
       */
      handleRechargeModalOk() {
        this.examinForm.validateFields((err, fieldsValue) => {
          if (err) {
            return;
          }
          const description = this.examinForm.getFieldsValue();
          const params = Object.assign({}, description, {
            id: this.currentId,
          });
          examineRecharge(params).then((res) => {
            if (res.success) {
              this.$message.success(this.examinForm.getFieldValue('rechargeStatus') === '101011' ?'审核成功' : '审核失败');
              this.handleExamineModalCancel();
              this.handleToSearchRecharge();
            } else {
              this.$message.error(res.message);
            }
          })
        })
      },
      /**
       * @description: 弹窗关闭
       * @return: void
       * @Author: HuangCheng
       */
      handleExamineModalCancel() {
        this.examinForm.resetFields();
        this.visibleExamineModal = false;
      },
      // 获取企业下拉信息
      getEnterpriseDownBox (enterpriseName) {
        getEnterpriseDownBox(enterpriseName).then(res => {
          this.conpanyLists = []
          this.conpanyLists = res.result
        })
      },
      // 添加订单
      handleAddRechargeModalOk () {
        this.addRechargeForm.validateFields((err, fieldsValue) => {
          if (err) {
            return;
          }
          const description = this.addRechargeForm.getFieldsValue();
          
          
          description.rechargeMode = '100921'
          
          addEnterpriseWalletRecharge(description).then(res => {
            if (res.success) {
              this.$message.success('添加成功')
              this.handleAddModalCancel();
              this.handleToSearchRecharge();
            } else {
              this.$message.error(res.message)
            }
          })
        })
      },
      handleAddModalCancel () {
        this.addRechargeForm.resetFields();
        this.addRechargeVisible = false;
      },
      // 导出
      exportTable (fileName) {
        if(!fileName || typeof fileName != "string"){
          fileName = "导出文件"
        }
        let param = {...this.queryParam};
        exportBbExcel(param).then((data)=>{
          if (!data) {
            this.$message.warning("文件下载失败")
            return
          } else {
            this.$message.success('导出成功')
          }
          if (typeof window.navigator.msSaveBlob !== 'undefined') {
            window.navigator.msSaveBlob(new Blob([data]), fileName+'.xls')
          }else{
            let url = window.URL.createObjectURL(new Blob([data]))
            let link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', fileName+'.xls')
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link) //下载完成移除元素
            window.URL.revokeObjectURL(url) //释放掉blob对象
          }
        })
      }
    }
  }
</script>
<style lang="less" scoped>
.headers{
  display: flex;
  // justify-content: space-between;
  background: rgba(255,255,255, 0.65);
  align-items: center;
  margin-bottom: 20px;
  padding: 20px;
  font-size: 16px;
  div{
    margin-right: 50px;
    width: 20%;
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
    // align-items: center;
    border-bottom: 1px solid rgb(232,232,232);
    span:last-child{
      font-size: 28px;
    }
  }
}
</style>